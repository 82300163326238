<template>
  <v-layout column>
    <LabelTextField
      class="mb-2"
      label="Wyszukaj"
      placeholder="Wyszukaj kod..."
      :value.sync="internalSearch"
      clearable
      hide-details
    ></LabelTextField>
    <FavoriteMedicalCodeDelegate
      class="mx-4"
      v-for="code in codes"
      :key="code.id"
      :item="code"
      :favorites.sync="internalFavorites"
      card
      hideOpenIcon
    ></FavoriteMedicalCodeDelegate>
  </v-layout>
</template>

<script>
import MedicalcodesService from "@/services/medicalcodes.service";
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import _ from "lodash";

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    search: {},
    favorites: {},
    model: {},
  },
  data() {
    return {
      codes: [],
      pagination: {
        selectable: true,
        rowsPerPage: 100,
        page: 1,
        min: 1,
        nofav: true
      },
    };
  },
  watch: {
    search() {
      if (!this.internalSearch || this.internalSearch.length == 0) {
        this.codes = [];
        this.internalSearch = null;
      } else {
        this.resetPagination(this.internalSearch);
        this.fetch();
      }
    },
  },
  computed: {
    internalFavorites: {
      get() {
        return this.favorites;
      },
      set(value) {
        this.$emit("update:favorites", value);
      },
    },
    internalSearch: {
      get() {
        return this.search;
      },
      set(value) {
        this.$emit("update:search", value);
      },
    },
  },
  components: {
    LabelTextField: () => import("@/components/LabelTextField"),
    FavoriteMedicalCodeDelegate: () =>
      import("@/components/delegates/FavoriteMedicalCodeDelegate"),
  },
  methods: {
    resetPagination(search) {
      this.pagination.search = search;
      this.pagination.page = 1;
    },
    fetch: _.debounce(function () {
      this.beforeRequest();
      MedicalcodesService.getCodes(this.pagination, this.model)
        .then((reply) => {
          this.codes = reply.results;
          this.reset();
        })
        .catch((error) => {
          this.handleError("Coś poszło nie tak.");
        });
    }, 200),
  },
};
</script>